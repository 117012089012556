<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true"
    :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '80vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-file" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">Previsualizar Pedido</strong>
        </h4>
      </div>
    </template>
    <div class="w-full text-xxs">
      <div class="grid grid-cols-1 xl:grid-cols-2 lg:gap-x-10 xl:gap-x-60">
        <div class="grid grid-rows-3">
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Código Cliente/Entidad: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="jsonServiceLayer.CardCode"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Nombre Cliente/Entidad: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="jsonServiceLayer.CardName"
                         disabled/>
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-1">
              <strong>Usuario: </strong>
            </div>
            <div class="col-span-3">
              <InputText class="p-inputtext-xxs inputsHeader text-xxs" v-model="jsonServiceLayer.U_PHR_UserWs"
                         disabled/>
            </div>
          </div>
        </div>
        <div class="grid grid-rows-3">
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha Contabilización: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <input v-model="jsonServiceLayer.TaxDate"
                     class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"
                     type="date">
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de documento: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <input v-model="jsonServiceLayer.DocDate"
                     class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"
                     type="date">
            </div>
          </div>
          <div class="grid grid-cols-4 items-center">
            <div class="xl:col-end-4 col-span-1">
              <strong>Fecha de vencimiento: </strong>
            </div>
            <div class="xl:col-end-5 col-span-3 xl:col-span-1">
              <input v-model="jsonServiceLayer.DocDueDate"
                     class="p-button-secondary p-inputtext-xxs text-xxs w-full border p-1 rounded-md mb-1 mt-1 flex-items-center"
                     type="date">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full my-2">
      <div>
        <DataTable
          :value="jsonServiceLayer.DocumentLines"
          class="p-datatable-sm text-xxs"
          showGridlines
          dataKey="id"
          :scrollable="true"
          scrollHeight="370px"
        >
          <template #empty>
            No hay datos para mostrar
          </template>
          <Column field="ItemCode" header="Artículo" headerStyle="justify-content: center;" style="min-width:30rem" align="left">
              <template #body="{data}">
                <div class="grid grid-cols-1 w-full">
                  <div class="flex justify-between items-center my-1">
                    <div>
                      <strong>Código Mx: </strong>{{ data.ItemCode }}
                    </div>
                    <div class="flex gap-2">
                      <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                             class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                      <Badge v-if="data.Controlado" value="Controlado"
                             class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="data.Regulado" value="Regulado"
                             class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                      <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                             class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                    </div>
                  </div>
                  <div>
                    <strong>Nombre: </strong>{{ data.ItemName }}
                  </div>
                </div>
              </template>
          </Column>
          <Column field="WarehouseCode" header="Almacén" headerStyle="justify-content: center;"
                  style="min-width:12rem" bodyStyle="justify-content: center;text-align: center">
            <template #body="{data}">
              <p>{{ data.WarehouseCode + ' - ' + data.WhsName }}</p>
            </template>
          </Column>
          <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;" style="min-width:5rem"
                  bodyStyle="justify-content: center;text-align: center">
            <template #body="{data}">
              <p>{{ $h.formatNumber(data.Quantity) }}</p>
            </template>
          </Column>
          <Column field="Price" header="Precio unitario" headerStyle="justify-content: center;"
                  style="min-width:8rem"
                  bodyStyle="justify-content: center;text-align: center">
            <template #body="{data}">
              <span>{{ $h.formatCurrency(data.UnitPrice) }}</span>
            </template>
          </Column>
          <Column field="LineTotal" header="Total Linea" headerStyle="justify-content: center;"
                  style="min-width:8rem"
                  bodyStyle="justify-content: center;text-align: center">
            <template #body="{data}">
              <span>{{ $h.formatCurrency(data.LineTotal) }}</span>
            </template>
          </Column>
          <Column field="" bodyStyle="text-align: center;justify-content: center;"
                  headerStyle="text-align: center;justify-content: center;"
                  style="min-width:5rem"
                  header="Acciones"
          >
            <template #body="slotProps">
              <div class="flex gap-4 justify-center">
                <Button icon="pi pi-trash" v-tooltip.top="'Borrar linea'"
                        class="p-button-rounded p-button-xs p-button-danger" @click="deleteLines(slotProps)"/>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8">
      <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
        <div class="flex flex-col">
          <p class="text-xxs mb-2">
            <strong>Comentarios: </strong>
          </p>
          <Textarea
            class="p-inputtext-xxs"
            :autoResize="true"
            v-model="jsonServiceLayer.Comments"
            rows="2"
            cols="40"
          />
        </div>
        <div class="flex flex-col">
          <p class="text-xxs mb-2">
            <strong>Entrada de diario: </strong>
          </p>
          <Textarea
            class="p-inputtext-xxs"
            disabled
            :autoResize="true"
            v-model="jsonServiceLayer.JrnlMemo"
            rows="2"
            cols="40"
          />
        </div>
        <div>
        </div>
      </div>
      <div class="text-xxs lg:col-span-1">
        <div class="flex justify-between border-b-2 pb-3">
          <span class="text-gray-600">Total sin descuento:</span>
          <span class="">{{ $h.formatCurrency(total) }}</span>
        </div>
        <div class="flex justify-between my-3">
          <span class="text-gray-600">Total descuento:</span>
          <span>{{ $h.formatCurrency(total * jsonServiceLayer.DiscountPercent / 100) }}</span>
        </div>
        <div class="flex justify-between my-3">
          <span class="text-gray-600">Total IVA:</span>
          <span class="">{{ $h.formatCurrency(jsonServiceLayer.totalIva) }}</span>
        </div>
        <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
          <span class="font-bold">Total:</span>
          <span class="">{{
              $h.formatCurrency((total - (total * jsonServiceLayer.DiscountPercent / 100)) + jsonServiceLayer.totalIva)
            }}</span>
        </div>
      </div>
    </div>
    <div class="flex justify-center lg:justify-end align-center pt-4">
      <Button
        icon="pi pi-send"
        label="Crear Pedido"
        class="p-button-rounded p-button-success"
        @click="sendDataSap()"/>
    </div>
  </Dialog>
</template>

<script>
import { computed, ref } from 'vue'
import { createPedidoSap } from '../../services/ofertas.service'
import Swal from 'sweetalert2'
import RecepcionPedidosService from '../../../logistica/services/rcp-pedidos.service'

export default {
  name: 'modalDetalleOferta',
  emits: ['updatePage'],
  setup (props, { emit }) {
    const displayModal = ref(false)
    const _RecepcionPedidosService = ref(new RecepcionPedidosService())
    const tableArray = ref([])
    const jsonServiceLayer = ref({})
    const total = computed(() => {
      return jsonServiceLayer.value.DocumentLines.reduce((total, current) => {
        return total + current.LineTotal
      }, 0)
    })
    const openModal = (values) => {
      tableArray.value = values.tableArray
      jsonServiceLayer.value = values.jsonServiceLayer
      displayModal.value = true
    }
    const deleteLines = ({ index }) => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar esta linea?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          jsonServiceLayer.value.DocumentLines.splice(index, 1)
          tableArray.value = tableArray.value.filter((x) => x.LineNumPedido !== index)
          tableArray.value.forEach((a, index) => {
            a.LineNum = index
          })
        }
      })
    }
    const sendDataSap = () => {
      if ([null, ''].includes(jsonServiceLayer.value.TaxDate) || [null, ''].includes(jsonServiceLayer.value.DocDate) || [null, ''].includes(jsonServiceLayer.value.DocDueDate)) {
        Swal.fire({
          icon: 'warning',
          title: 'No es posible crear el pedido',
          text: 'Por favor ingrese una fecha valida para la creación del documento',
          showConfirmButton: true,
          confirmButtonText: 'Ok'
        })
        return
      }
      if (!jsonServiceLayer.value.DocumentLines.length) {
        Swal.fire({
          icon: 'warning',
          title: 'No es posible crear el pedido',
          text: 'El Pedido no posee medicamentos',
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: 'Ok'
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea crear el Pedido?',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Si, crear',
        cancelButtonText: 'No, cancelar'
      }).then(async ({ value }) => {
        if (value) {
          const payload = {
            jsonServiceLayer: jsonServiceLayer.value,
            tableArray: tableArray.value.map((x, index) => {
              x.LineNum = index
              return x
            })
          }
          await createPedidoSap(payload).then(({ data }) => {
            if (data.data.DocEntry) {
              Swal.fire({
                icon: 'success',
                title: 'Pedido creado exitosamente',
                text: `Se creo el pedido ${data.data.DocNum}`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then(() => {
                _RecepcionPedidosService.value.imprimirPdfPedido(data.data.DocNum)
                if (data.errores && data.errores.length > 0) {
                  let html = ''
                  for (const e of data.errores) {
                    html += `<span>${e.message}</span><br />`
                  }
                  Swal.fire({
                    icon: 'warning',
                    title: 'Se produjeron algunos errores al cerrar las lineas de la solicitud',
                    html: html,
                    showConfirmButton: true,
                    confirmButtonText: 'OK'
                  })
                }
                displayModal.value = false
                emit('updatePage')
              })
            }
          }).catch((err) => {
            console.log('Error crear pedido', err)
          })
        }
      })
    }
    return {
      openModal,
      displayModal,
      jsonServiceLayer,
      deleteLines,
      sendDataSap,
      total
    }
  }
}
</script>

<style scoped>
.header {
  display: grid;
  box-shadow: 3px 3px 3px 4px whitesmoke;
}

.inputsHeader {
  background-color: #eef1f5;
  width: 100%;
  height: 100%;
}

.p-card.p-card-body {
  padding-bottom: 0.2rem;
  padding-top: 0.2rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.footerModal {
  display: flex;
  padding: 10px;
}

::v-deep(.pi) {
  font-size: 0.8rem;
}

::v-deep(.p-column-header-content) {
  justify-content: center;
}

::v-deep(.p-card-content) {
  padding: 0rem;
}
</style>
